import { z } from 'zod';
import { Address } from '@shared/modules/address/model';
import { Role } from '@modules/roles/model';
import { Issue } from '@modules/issues/model';
import { NonEmptyString, nonEmptyStringSchema } from '@shared/schemas';
import { Translations } from '@core/translations';
import { SearchFilter } from '@shared/modules/filter';
import { MantineColor } from '@mantine/core';
import { Account } from '@modules/account/model';
import { GPSLocation } from '@shared/modules/maps/model';
import { Parcel } from '@modules/parcels/model';
import { LocalDate, LocalDateTime } from '@shared/modules/dates';
import { ElectricCurrent, ElectricPotential, Percentage, Power, Temperature, Volume } from '@shared/model';
import { Signal } from '@shared/modules/signal/model';
import { Battery } from '@shared/modules/battery/model';
import type { Favourite } from '@modules/favourites/model';
import { PestCountWithDate } from '@shared/modules/pest/model';
import { SensorCommand } from '@modules/sensors-command/model';

export namespace Sensor {
  export const Id = z.string().uuid().brand('SensorId');
  export type Id = z.infer<typeof Id>;

  export const SerialNumber = z.string().brand('SensorSerialNumber');
  export type SerialNumber = z.infer<typeof SerialNumber>;

  export const MessageId = z.string().uuid().brand('SensorMessageId');
  export type MessageId = z.infer<typeof MessageId>;

  export interface RangeItem {
    id: Id;
    serialNumber: string;
    address: Address | null;
    ownerId: Account.Id | null;
    ownerLabel: string | null;
    ownerType: Role.AccountType | null;
    issues: Issue.Info | null;
    usage: Usage;
    contractPrivateUsage: boolean | null;
    signal: Signal.Strength | null;
    battery: Battery.Level | null;
    state: State | null;
    canAccessDetail: boolean;
  }

  export interface SearchItem {
    id: Id;
    number: string;
  }

  export interface Filter extends SearchFilter, Favourite.Filter.WithFavouriteFilter {
    onlyMine: boolean | null;
  }

  export enum Usage {
    Selling = 'selling',
    Renting = 'renting',
    Support = 'support',
  }

  export const usageLabel: Translations.TranslatedEnumLabel<Usage> = {
    [Usage.Selling]: t => t('usage.selling', { ns: 'sensors' }),
    [Usage.Renting]: t => t('usage.renting', { ns: 'sensors' }),
    [Usage.Support]: t => t('usage.support', { ns: 'sensors' }),
  };

  export enum State {
    EnService = 'EnService',
    Acquisition = 'Acquisition',
    Traitement = 'Traitement',
    Maintenance = 'Maintenance',
    Defaut = 'Defaut',
    Vol = 'Vol',
    Arrete = 'Arrete',
    Inconnu = 'Inconnu',
  }

  export const stateLabel: Translations.TranslatedEnumLabel<State> = {
    [State.EnService]: t => t('state.en-service', { ns: 'sensors' }),
    [State.Acquisition]: t => t('state.aquisition', { ns: 'sensors' }),
    [State.Traitement]: t => t('state.traitement', { ns: 'sensors' }),
    [State.Maintenance]: t => t('state.maintenance', { ns: 'sensors' }),
    [State.Defaut]: t => t('state.defaut', { ns: 'sensors' }),
    [State.Vol]: t => t('state.vol', { ns: 'sensors' }),
    [State.Arrete]: t => t('state.arrete', { ns: 'sensors' }),
    [State.Inconnu]: t => t('state.inconnu', { ns: 'sensors' }),
  };

  export const stateColor: Record<State, MantineColor> = {
    [State.EnService]: 'lime.9',
    [State.Acquisition]: 'lime.3',
    [State.Traitement]: 'blue.2',
    [State.Maintenance]: 'yellow.8',
    [State.Defaut]: 'yellow.3',
    [State.Vol]: 'orange.8',
    [State.Arrete]: 'dark.7',
    [State.Inconnu]: 'gray.5',
  };

  export const CreateParams = z.object({
    serialNumber: nonEmptyStringSchema,
    usage: z.nativeEnum(Usage),
    hasWeatherSensor: z.boolean(),
    hasProviderSupport: z.boolean(),
  });

  export type CreatePrams = z.infer<typeof CreateParams>;

  export const AffectParams = CreateParams.pick({
    serialNumber: true,
  });

  export type AffectParams = z.infer<typeof AffectParams>;

  export enum AffectErrors {
    SensorAlreadyLinked = 'already-linked',
    SensorNotOwned = 'not-owned',
    SensorAffectationForbidden = 'affectation-forbidden',
    RentContractNotValidated = 'rent-contract-not-validated',
    SubscriptionError = 'subscription-error',
  }

  export const UpdateParams = z.object({
    tags: z.array(z.string()),
    comment: z.string().nullish(),
  });

  export type UpdateParams = z.infer<typeof UpdateParams>;

  export const UpdateBody = UpdateParams.extend({
    parcelId: z.string().uuid().brand<'ParcelId'>().nullish(),
  });
  export type UpdateBody = z.infer<typeof UpdateBody>;

  export interface Info {
    id: Sensor.Id;
    serialNumber: string;
    usage: Usage;
    owner: Account.Light | null;
    provider: Account.Light | null;
    lastUpdate: Sensor.DateTime | null;
    issues: Issue.Info | null;
    signal: Signal.Strength | null;
    weatherStationSerial: string | null;
    contractPeriod: ContractPeriod | null;
    subscriptionPeriod: ContractPeriod | null;
    hasWeatherSensor: boolean;
    hasProviderSupport: boolean;
    dsTags: Array<string>;
    customerTags: Array<string>;
    dsComment: string | null;
    customerComment: string | null;
    activationDate: Sensor.DateTime | null;
    state: State | null;
    location: GPSLocation | null;
    parcelId: Parcel.Id;
    stm32Version: string | null;
    lastCommand: {
      command: SensorCommand;
      date: LocalDateTime;
    } | null;
  }

  export interface ContractPeriod {
    start: LocalDate | null;
    end: LocalDate | null;
  }

  export interface DateTime {
    sensorZone: LocalDateTime;
    userZone: LocalDateTime;
  }

  export namespace Weather {
    export enum Source {
      Sensor = 'sensor',
      Sencrop = 'sencrop',
      Weenat = 'weenat',
      None = 'none',
    }

    export interface Measure {
      lastUpdate: DateTime;
      temp: Temperature;
      tempMin: Temperature;
      tempMax: Temperature;
      rainLastHour: Volume;
      rainDay: Volume;
      humidity: Percentage;
      humidityMin: Percentage;
      humidityMax: Percentage;
    }

    export const LinkParams = z.discriminatedUnion('type', [
      z.object({ type: z.literal(Source.Sencrop), sencropId: nonEmptyStringSchema }),
      z.object({ type: z.literal(Source.Weenat), weenatSerial: nonEmptyStringSchema }),
    ]);
    export type LinkParams = z.infer<typeof LinkParams>;

    export const LinkBody = z.object({
      type: z.union([z.literal(Source.Sencrop), z.literal(Source.Weenat)]),
      serialNumber: nonEmptyStringSchema,
    });
    export type LinkBody = z.infer<typeof LinkBody>;
  }

  export interface Weather {
    source: Weather.Source;
    measure: Weather.Measure | null;
  }

  export namespace Histo {
    export interface Value {
      date: DateTime;
      value: number;
    }
  }

  export interface Histo {
    lastUpdate: DateTime;
    values: Array<Histo.Value>;
  }

  export interface AcquisitionReport {
    captured: number;
    captureErrors: number;
    ramUsage: Percentage;
  }

  export interface ProcessingReport {
    computed: number;
    computingError: number;
    neutralized: number;
  }

  export namespace File {
    export const Id = z.string().uuid().brand('SensorFileId');
    export type Id = z.infer<typeof Id>;
  }

  export interface File {
    id: File.Id;
    name: NonEmptyString;
    dateTime: DateTime;
    url: string;
  }

  export interface Diagnosis {
    lastUpdated: DateTime;
    sharpness: number;
    sharpnessDeviation: number;
    brightness: number;
    brightnessDeviation: number;
    complexity: number;
    similarity: number;
  }

  export interface Voltage {
    lastUpdated: DateTime;
    inputVoltage: ElectricPotential;
    inputCurrent: ElectricCurrent;
    chargeCurrent: ElectricCurrent;
    discharge: ElectricCurrent;
    systemVoltage: ElectricPotential;
    power: Power;
  }

  export interface Usb {
    occupation: Percentage;
  }

  export namespace Info {
    export enum Stm32State {
      Init = 'init',
      PoweringOn = 'powering-on',
      StorageLoad = 'storage-load',
      PowerSupplyMissing = 'power-supply-missing',
      CheckPeriph = 'check-periph',
      Acquisition = 'acquisition',
      Maintenance = 'maintenance',
      WaitingRestart = 'waiting-restart',
      Storage = 'storage',
      Fault = 'fault',
      Test = 'test',
      Theft = 'theft',
      WaitingStorage = 'waiting-storage',
    }

    export enum LinuxState {
      Stop = 'stop',
      Init = 'init',
      UpdateInstall = 'update-install',
      Ready = 'ready',
      Acquiring = 'acquiring',
      Processing = 'processing',
      ImageCapture = 'image-capture',
      UsbCopy = 'usb-copy',
      Fault = 'fault',
    }
  }

  export interface ModuleState {
    stm32State: Info.Stm32State;
    linuxState: Info.LinuxState;
  }

  export enum ShutterState {
    Opened = 'opened',
    Closed = 'closed',
  }

  export interface Data {
    id: Id;
    serialNumber: SerialNumber;
    pestCount: PestCountWithDate | null;
    weather: Weather | null;
    histo: Histo | null;
    snailHisto: Histo | null;
    acquisitionReport: AcquisitionReport | null;
    processingReport: ProcessingReport | null;
    testImage: File | null;
    neutralisationImages: Array<File>;
    captureImages: Array<File>;
    diagnosis: Diagnosis | null;
    voltage: Voltage | null;
    usb: Usb | null;
    modules: ModuleState | null;
    hatchStatus: ShutterState;
    lidStatus: ShutterState;
  }

  export interface Light {
    id: Id;
    serialNumber: SerialNumber;
    signal: Signal.Strength | null;
    battery: Battery.Level | null;
    state: State | null;
    privateUsage: boolean | null;
    usage: Sensor.Usage;
    issueInfo: Issue.Info | null;
    defaultCode: string | null;
    dsTags: Array<string>;
    customerTags: Array<string>;
  }

  export interface UltraLight {
    id: Id;
    serialNumber: string;
    isSupport: boolean;
    parcel: Parcel.Light | null;
  }
}

export interface Sensor {
  id: Sensor.Id;
  ownerId: Account.Id | null;
  serialNumber: string;
  usage: Sensor.Usage;
  hasWeatherSensor: boolean;
  hasProviderSupport: boolean;
  dsTags: Array<string>;
  customerTags: Array<string>;
  dsComment: string | null;
  customerComment: string | null;
  activationDate: Sensor.DateTime | null;
  state: Sensor.State | null;
}
